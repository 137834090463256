<template>
  <div>
    <div class="section-shaped my-0 skew-separator skew-mini ">
      <v-img
        class="page-header header-filter  header-small "
        max-height="190px"
        src="/media/test-biz-header.png"
        gradient="to top right, rgba(0,0,0,.53), rgba(25,32,72,.7)"
      >
      </v-img>
    </div>

    <div class="main main-raised" style="background:#fff;min-height:700px;">
      <div class="container">
        <div class="row no-gutters">
          <div class="col-lg-5 mr-1">
            <div class="display-4">
              Seller Billing Center
            </div>
          </div>

          <div class="col-sm mr-1"></div>

          <div class="col-sm mr-1">
            <md-card class="bg-secondary h-100">
              <md-card-content>
                <div class="text-white">Total Accounts Balance</div>
                <div class="text-white display-2">$2005.00</div>
              </md-card-content>
            </md-card>
          </div>

          <div class="col-sm">
            <md-card class="bg-secondary h-100">
              <md-card-content>
                <div class="text-white">Average Age:</div>
                <div class="text-white display-2">77 Days</div>
              </md-card-content>
            </md-card>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <v-card>
              <v-form>
                <div class="row">
                  <div class="col-lg-8">
                    <v-card>
                      <v-card-title>
                        Outstanding Invoices
                        <v-spacer></v-spacer>
                        <v-text-field
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Search"
                          single-line
                          hide-details
                          class="mb-3"
                        ></v-text-field>
                      </v-card-title>

                      <v-data-table
                        :headers="headers"
                        :items="orders"
                        :search="search"
                      >
                        <template #[`item.actions`]="{ item }">
                          <v-btn small text class="mr-2" @click="Pay(item)">
                            Pay Now
                          </v-btn>
                          <v-btn small text @click="View(item)">
                            View
                          </v-btn>
                        </template>
                        <template #no-data>
                          <v-btn color="primary" @click="initialize">
                            Reset
                          </v-btn>
                        </template>
                      </v-data-table>
                    </v-card>
                  </div>
                </div>
              </v-form>
            </v-card>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-4">
      <v-card> </v-card>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    selected: [],
    search: "",
    headers: [
      {
        text: "Invoice #",
        align: "start",
        sortable: false,
        value: "id",
      },
      { text: "Company", value: "name" },
      { text: "Current Balance", value: "total" },
      { text: "Actions", value: "actions", sortable: false },
    ],

    orders: [
      {
        id: "10001",
        invoiceid: "20000",
        name: "Josh's Bikes",
        user: "Tom Smith",
        date: "12-10-2020",
        total: "$14,000.00",
        status: "Shipped",
      },
      {
        id: "10002",
        invoiceid: "204400",
        name: "Josh's Bikes",
        user: "Anne Clarke",
        date: "12-10-2020",
        total: "$14,000.00",
        status: "Shipped",
      },
      {
        id: "10003",
        invoiceid: "20000",
        name: "Discount Outdoor",
        user: "Anne Clarke",
        date: "12-10-2020",
        total: "$14,000.00",
        status: "Shipped",
      },
      {
        id: "10004",
        invoiceid: "20000",
        name: "Little Shop 23",
        user: "Anne Clarke",
        date: "12-10-2020",
        total: "$14,000.00",
        status: "Pending",
      },
      {
        id: "10005",
        invoiceid: "20000",
        name: "Best Store 453",
        user: "Anne Clarke",
        date: "12-10-2020",
        total: "$81,000.00",
        status: "Pending",
      },
      {
        id: "10006",
        invoiceid: "20000",
        name: "Gander Springs",
        user: "Bob Smith",
        date: "12-10-2020",
        total: "$14,676.00",
        status: "Shipped",
      },
      {
        id: "10007",
        invoiceid: "20000",
        name: "Toms Boats",
        user: "Tom Jones",
        date: "12-10-2020",
        total: "$8,000.00",
        status: "Shipped",
      },
      {
        id: "10008",
        invoiceid: "20000",
        name: "Bobs Outdoor",
        user: "Susan Orange",
        date: "12-10-2020",
        total: "$76,000.00",
        status: "Shipped",
      },
      {
        id: "10009",
        invoiceid: "20000",
        name: "Discount Outdoor",
        user: "Terry Clarke",
        date: "12-10-2020",
        total: "$45,000.00",
        status: "Backorder",
      },
      {
        id: "10010",
        invoiceid: "20000",
        name: "Discount Outdoor",
        user: "Guy Clarke",
        date: "12-10-2020",
        total: "$14,000.00",
        status: "Shipped",
      },
    ],
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },
};
</script>

<style scoped>
.main-raised {
  margin: -120px 30px 0px;
  border-radius: 6px;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.main {
  background: #eee;
  position: relative;
  z-index: 3;
}
</style>
